import React from 'react'
import { Link } from 'react-router-dom'


export const InsuranceCard = (props) => {


    return (
        <div className="col-span-2 lg:col-span-1 text-center shadow-xl bg-grey-2 rounded-3xl " style={{paddingTop:"160px"}}>
            <div className="min-h-[280px]">
                <img width="300px" src={props.img} className="mx-auto -mt-[200px]" alt='Soluciones' />
            </div>
            <h2 className="mt-4 mb-2 text-orange">{props.tittle}</h2>
            <p className="px-12 lg:min-h-[200px]">{props.text}</p>
            <Link to={props.page} className="rounded-2xl inline-block my-8 px-8 py-1 bg-grey text-white">VER MÁS</Link>
        </div>
    )
}
