import React from 'react'

export const CardAboutUs = (props) => {
  return (
    <div className="col-span-3 lg:col-span-1">
    <img width="80px" className="mx-auto lg:ml-0" src={props.icon} alt=''/>
    <h2 className="mt-4 mb-2 text-orange">{props.tittle}</h2>
    <p>{props.text}</p>
</div>
  )
}
