import React from 'react'

export const B2B2CInfo = () => {
    return (
        <div className="flex lg:px-32 mx-0 md:mx-32 lg:mx-0">
            <div className="w-full text-center">
                <div className='flex flex-wrap'>
                    <p>Trazamos soluciones estratégicas y tecnológicas a medida de nuestros clientes, las cuales les permitan proveer una serie de productos y servicios complementarios a su foco de actuación, asegurando el menor time-to-market para el roll-out de productos y nuevos deals.</p>
                </div>
                <h1 className="mt-8 mb-2">¿Cómo lo hacemos?</h1>
                <p>Estudio de mercado dónde analizamos la situación actual de la compañía, identificamos los principales pain point (puntos de dolor) dónde diagnosticamos las necesidades y oportunidades, priorizamos acciones y delineamos un plan de acción estratégico para un desarrollo de una solución rentable y sostenible a largo plazo.</p>
            </div>
        </div>
    )
}
