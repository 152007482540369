import React from 'react'
import { ContactForm } from './ContactForm/ContactForm'
import { ContactInfo } from './ContactInfo/ContactInfo'

export const ContactContainer = (props) => {
    return (
        <section id="contacto" className="bg-grey-2 px-4 lg:px-32 py-8 lg:py-16 text-center text-grey">
            
            {props.page === 'index' ? <ContactInfo /> : props.page === 'productores' ? <h1 className="mb-8">Contacto</h1> :  <h1 className="mb-8">Solicitá una demo</h1>}       
            <ContactForm email_destino = {props.email_destino}/>
        </section>
    )
}
