import React, {useState,useEffect} from 'react'
import { ConsultingInfo } from '../ConsultingInfo/ConsultingInfo'
import { getAreaInfo } from '../ConsultingArea/ConsultigAreaData'
import { ConsultingArea } from '../ConsultingArea/ConsultingArea'

export const ConsultingContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
      getAreaInfo().then(data => {
        setInfo(data)
        
      })
    }, [])

    return (
        <section id="us" className="px-4 lg:px-32 pt-8 lg:pt-16 text-grey">
            <ConsultingInfo />
            <h1 className="text-center my-8">Conocé nuestras áreas de consultoría</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            {info.map(area => <ConsultingArea {...area} key={area.id}/>)}
            </div>
        </section>
    )
}
