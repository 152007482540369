import React,{useEffect,useState} from 'react'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'

export const MarketingDirectoContainer = () => {
    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("marketing-directo").then(data => {
            setInfo(data)

        })

    }, [])
    return (
        <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
            <Link to={"/tpa"} className="hover:font-bold text-orange">Volver</Link>
            <GenericInfo text="A través de AG TPA definimos y desarrollamos acciones de Marketing Directo que generan un fuerte impacto en las operaciones de nuestros clientes. Estas acciones de Marketing Directo facilitan el contacto inmediato con el posible comprador a fin de promover un producto, servicio o idea, empleando para ello los medios que mejor se adecuan a las necesidades de nuestro cliente."  tittle="Nuestros productos y servicios" />
            <div className="grid grid-cols-2 gap-8 my-8">
                {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
            </div>
        </section>
    )
}
