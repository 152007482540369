import React, {useState,useEffect} from 'react'
import { Tpainfo } from '../TpaInfo/Tpainfo'
import { getAreaInfo } from '../TpaAreaData'
import {ConsultingArea} from '../../Consulting/ConsultingArea/ConsultingArea'

export const TpaContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
      getAreaInfo().then(data => {
        setInfo(data)
        
      })
    }, [])

  return (
    <section id="us" className="px-4 lg:px-64 py-8 lg:py-16 text-grey">
        <Tpainfo/>
        <h1 className="text-center my-8">Conocé nuestras áreas de expertise</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            {info.map(area => <ConsultingArea {...area} key={area.id}/>)}
        </div>
    </section>
  )
}
