const info = [
    { 
        id: '1', 
        page: "atencion-clientes",
        tittle:'Atención Al Cliente >',
    },
    { 
        id: '2', 
        page: "operaciones",
        tittle:'Operaciones >',
    },
    { 
        id: '3', 
        page: "marketing-directo",
        tittle:'Marketing Directo >',
    },
    { 
        id: '4', 
        page: "capacitacion",
        tittle:'Capacitación >',
    },
    { 
        id: '5', 
        page: "sistemas",
        tittle:'Sistemas >',
    },
]

export const getAreaInfo = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info)
        }, 0)
    })
}