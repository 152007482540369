import React,{useState,useEffect} from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { EstrategiaContainer } from './EstrategiaContainer/EstrategiaContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const Estrategia = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("estrategia").then(data => {
      setInfo(data)
    })
  }, [])
  return (
    <div className='mt-23'>
        <Carousel page="estrategia"/>
        <EstrategiaContainer/>
        <ContactContainer page={"estrategia"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
