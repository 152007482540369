import React from 'react'
import { Link } from 'react-router-dom'

export const CarouselItem = (props) => {
    
  return (
    <div className={`carousel-item ${props.id === "1" && "active"} relative float-left w-full h-[60vh]`}>
    <img src={props.img} className="object-cover block w-full h-[60vh]" alt="..." />
    <div className="carousel-caption pt-0 ml-10 left-[10%] md:left-[5%] text-left absolute lg:right-0 top-[10%] lg:top-[30%] lg:text-right lg:mr-52">
        <h1 className="text-3xl lg:text-5xl">{props.tittle}</h1>
        <p className="text-lg lg:text-2xl mt-4 mb-4 lg:mb-12">{props.text1}<br className="hidden lg:block"/>
            {props.text2}</p>
        {props.page === "index" || props.page === "insurance-soluciones" ? <Link to={props.to} className="bg-orange rounded-2xl px-4 py-2  text-sm" >VER MÁS INFORMACIÓN</Link> : "" }    
        
    </div>
</div>
  )
}
