import React,{useState,useEffect} from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { FinanzasContainer } from './FinanzasContainer/FinanzasContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const Finanzas = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("finanzas").then(data => {
      setInfo(data)
    })
  }, [])
  return (
    <div className='mt-23'>
        <Carousel page="finanzas"/>
        <FinanzasContainer/>
        <ContactContainer page={"finanzas"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
