import React,{useState,useEffect} from 'react'
import { CapacitacionContainer } from './CapacitacionContainer/CapacitacionContainer'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { Carousel } from '../../Carousel/Carousel'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'


export const Capacitacion = () => {

  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("capacitacion").then(data => {
      setInfo(data)
    })
  }, [])

  return (

    <div className='mt-23'>
        <Carousel page="capacitacion"/>
        <CapacitacionContainer/>
        <ContactContainer page={"capacitacion"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
