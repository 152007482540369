import React from 'react'

export const FinanzasInfo = () => {
    return (
        <>
            <p className="text-center my-8 lg:px-32">Las Finanzas Corporativas se orientan a construir mecanismos para que las empresas puedan crear valor a través del uso eficiente de los recursos financieros y desarrollen habilidades para mantener ese valor en el tiempo.</p>
            <h1 className="text-center">Nuestros productos y servicios</h1>
        </>
    )
}
