import React,{useState,useEffect} from 'react'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'

export const EstrategiaContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("estrategia").then(data => {
            setInfo(data)

        })

    }, [])

  return (
    <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
    <Link to={"/consulting"} className="hover:font-bold text-orange"> Volver</Link>
    <GenericInfo text={`La definición de una estrategia de crecimiento adecuada para el negocio, el desarrollo de modelos operativos innovadores y el diseño de programas que los alcancen, son los servicios que el equipo de AG Consulting provee para que nuestros clientes puedan ampliar sus ventajas competitivas de manera sostenible y obtener los resultados que buscan.`} text2="El proceso estratégico parte de la Misión de una compañía y de su Visión para el largo plazo, y en tres etapas complementarias busca establecer una estrategia para cierto período de tiempo." tittle="Nuestros productos y servicios"/>
    <div className="grid grid-cols-2 gap-8 my-8">
    {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
    </div>
</section>
  )
}
