import React,{useEffect,useState} from 'react'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'

export const CapacitacionContainer = () => {
    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("capacitacion").then(data => {
            setInfo(data)

        })

    }, [])
    return (
        <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
            <Link to={"/tpa"} className="hover:font-bold text-orange">Volver</Link>
            <GenericInfo text="En AG TPA consideramos que la administración de las fuerzas de ventas es uno de los brazos más ejecutivos dentro de las estrategias corporativas. Las fuerzas de ventas son los recursos humanos más cercanos a los clientes, son quienes acercan las innovaciones que éstos requieren e intermedian entre ellos y deben consolidar un discurso homogéneo, atractivo y eficaz."  tittle="Metodología" />
            <div className="grid grid-cols-2 gap-8 my-8">
                {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
            </div>
        </section>
    )
}
