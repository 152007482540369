import React, { useState, useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export const ContactForm = (props) => {

    const [mailDestino, setMailDestino] = useState(props.email_destino)
    useEffect(() => {
        setMailDestino(props.email_destino)
    }, [props.email_destino])

    // Valores iniciales de los inputs.
    const initialValues = {
        nombre: "",
        mail_cliente: "",
        telefono: "",
        combo: "",
        consulta: "",
    }
    // Esquema de validaciones para los inputs.
    const numRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

    const validationSchema = Yup.object({
        nombre: Yup.string().min(5, "Minimo 5 Caracteres").required('Nombre y Apellido Requerido'),
        mail_cliente: Yup.string().email("Formato Invalido de Email").required('Email Requerido'),
        telefono: Yup.string().matches(numRegex, "Número de telefono no valido").required("Telefono Requerido"),
        combo: Yup.string().required("Seleccione Opción"),
        consulta: Yup.string().required("Ingrese su Consulta")
    })

    const EnviarMail = (values, formikBag) => {
        debugger;
        values.mail_destino = mailDestino
        emailjs.send('service_agglobalservices', 'template_n2u4s2j', values, "bHjCjoeWnIh-XVUDs")
            .then(function (response) {
                toast.success("Consulta Enviada!");
                formikBag.resetForm();
            }, function (error) {
                toast.error("Error para enviar consulta, intentelo de nuevo mas tarde..");
            });
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true} onSubmit={(values, formikBag) => {
            EnviarMail(values, formikBag)
        }}>
            <Form>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* <Field className="hidden" name="mail_destino" value={initialValues.mail_destino} /> */}
                <div className="grid grid-cols-2 gap-8 bg-white rounded-xl shadow-xl p-8  mx-0 md:mx-32 lg:mx-0">
                    <div className="col-span-2 lg:col-span-1">
                        <Field type="text" className="focus:outline-none text-sm mb-4 border-0 border-b border-grey-2 w-full"
                            placeholder="Nombre y apellido *" name="nombre" />
                        <ErrorMessage
                            name="nombre"
                            component="div"
                            className="field-error text-danger"
                        />
                        <Field type="text" className="focus:outline-none text-sm mb-4 border-0 border-b border-grey-2 w-full"
                            placeholder="Email *" name="mail_cliente" />
                        <ErrorMessage
                            name="mail_cliente"
                            component="div"
                            className="field-error text-danger"
                        />
                        <Field type="text" className="focus:outline-none text-sm mb-4 border-0 border-b border-grey-2 w-full"
                            placeholder="Teléfono *" name="telefono" />
                        <ErrorMessage
                            name="telefono"
                            component="div"
                            className="field-error text-danger"
                        />
                        <Field as="select" name="combo" className="w-full focus:outline-none text-sm mb-4 border-0 border-b border-grey-2">
                            <option value={""}>Seleccione una opción *</option>
                            <option value={"B2B2C"}>B2B2C</option>
                            <option value={"Productor"}>Soy productor</option>
                            <option value={"Consulting"}>Consulting</option>
                            <option value={"TPA"}>TPA</option>
                        </Field>
                        <ErrorMessage
                            name="combo"
                            component="div"
                            className="field-error text-danger"
                        />
                        <p className="text-orange text-sm text-left">*Los campos marcados son de caracter obligatorio</p>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                        <Field as="textarea" className="w-full border border-grey-2 p-2" rows="6" placeholder="Escribí tu consulta aquí *" name="consulta" />
                        <ErrorMessage
                            name="consulta"
                            component="div"
                            className="field-error text-danger"
                        />
                        <input type="submit" className="bg-grey text-white px-6 py-1 rounded-3xl text-sm float-right"
                            value="ENVIAR" />
                    </div>
                </div>
            </Form>
        </Formik>
    )
}
