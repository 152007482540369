import React, { useState } from 'react'


export const InsuranceInfo = () => {
    const [mas, setMas] = useState(false)
    const masTexto = <p className="ver-mas-texto my-4">
        Proveemos soluciones Saas (Software as a Service). Nuestros clientes no necesitan instalar, mantener y actualizar
        hardwares y softwares, el acceso es fácil y simple, sólo es necesario contar con una buena conexión a internet.
        Nuestros clientes obtienen como resultado eficientización de consumo/costos, mayor agilidad en la implementación y
        actualización de las soluciones empresariales.
    </p>
        




    return (
        <div className="flex lg:px-32 mx-0 md:mx-32 lg:mx-0">
            <div className="text-center">
                <h1 className="mb-8">Insurance Solutions</h1>
                <p>Diseñamos, implementamos y gestionamos propuestas integrales e innovadoras para la comercialización de seguros
                    con apoyo en desarrollos tecnológicos afines a la industria. Nuestras herramientas cuentan con la flexibilidad para
                    proveer soluciones B2B2C, integradas y sincronizadas en todas las fases del negocio, facilitando la dinámica de
                    comunicación con los sistemas de nuestros clientes.<br /><br />
                    Nuestro punto de partida es satisfacer las necesidades de nuestros clientes poniendo a su disposición la diversidad y
                    accesibilidad de servicios IT desarrollados 100% inhouse. Nuestro know how nos permite trabajar en conjunto con
                    cada uno de ellos, hacemos soluciones a medida, tomando como base y optimizando las herramientas propias de
                    nuestros clientes, cómo también customizando nuestros desarrollos, co-creando sistemas específicos. Ejecutamos
                    aplicaciones empresariales cruciales con elevados niveles de rendimiento, escalabilidad y disponibilidad al tiempo que
                    reduces tus costos.</p>
                {mas === false ? "" : masTexto}
                <p className="text-orange cursor-pointer ver-mas" onClick={() => { setMas(!mas) }}>{mas === false ? "Ver más" : "Menos"}
                    <span id="ver-mas"> 
                        <svg className="chevron-down inline w-5 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d={mas === false ? "M19 9l-7 7-7-7" :"M5 15l7-7 7 7" } />
                        </svg>
                    </span>
                </p>
            </div>
        </div>
    )
}
