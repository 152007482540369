import {AiOutlineShoppingCart} from 'react-icons/ai';
import {RiBankLine} from 'react-icons/ri';
import {MdLightbulbOutline} from 'react-icons/md';
const info = [
    { 
        id: '1', 
        tittle:'RETAIL',
        children: <AiOutlineShoppingCart className='m-auto my-4 min-h-[120px] svg-orange tam' size={50}/>,
        moreText1: `Nuestra oferta de Seguros Masivos a través de Retailers tiene como objetivo proveer un servicio complementario al foco de actuación de la empresa, de manera que ésta agregue valor a su negocio por el fortalecimiento de su marca, la actualización de la base de datos de sus clientes, el aumento del flujo de personas en sus locales, la reducción de la pérdida de clientes gracias a su fidelización al igual que de propuesta de valor para sus socios estratégicos y empleados por medio de planes de afinidad.`,
        moreText2:"",
        moreItems:"Multicotizador en línea que permite cotizar y emitir online una amplia variedad de Seguros | Menor time-to-market para el roll-out de productos y nuevos deals. | Gestión de pólizas multi-ramo y Multi-compañia. | Registro de interacciones con clientes. | Liquidación de cobranzas y comisiones con gestión de redes comerciales. | Manejo de workflow de ventas con estados y cumplimiento de metas.",

    },
    { 
        id: '2', 
        tittle:'UTILITIES',
        children: <MdLightbulbOutline className='m-auto my-4 min-h-[120px] svg-orange tam' size={50}/>,
        moreText1: `La oferta de Seguros Masivos para compañías que proveen servicios públicos tiene como objetivo diversificar y ampliar el foco de actuación de la empresa ofreciendo un servicio complementario. Una solución estratégica que abarca todo el proceso de comercialización, facilitando la incorporación del producto con el asesoramiento enfocado en la necesidad del cliente.`,
        moreText2:`Estos seguros están destinados a distribuidoras de energía eléctrica, gas o agua; concesionarias de limpieza; operadores de telefonía fija y celular; proveedores de Internet; operadores de TV por cable; operadores de transportes públicos y demás compañías cuyo mercado de actuación sea la satisfacción de las necesidades primordiales de una sociedad.`,
        moreItems:"Multicotizador en línea que permite cotizar y emitir online una amplia variedad de Seguros | Menor time-to-market para el roll-out de productos y nuevos deals. | Gestión de pólizas multi-ramo y Multi-compañia. | Registro de interacciones con clientes. | Liquidación de cobranzas y comisiones con gestión de redes comerciales. | Manejo de workflow de ventas con estados y cumplimiento de metas. | Asoramiento e implementación 360° de la estrategia competitiva.",

    },
    { 
        id: '3', 
        tittle:'BANCASEGUROS',
        children: <RiBankLine className='m-auto my-4 min-h-[120px] svg-orange tam' size={50}/>,
        moreText1: `La oferta de Seguros Masivos para compañías Bancos e instituciones financieras (empresas de inversiones, tarjetas de crédito, entre otras). Integramos sistemas tecnológicos que permiten lanzar nuevos productos a través de procesos automatizados y customizables. Implementamos y gestionamos soluciones end-to-end.`,
        moreText2:"",
        moreItems:"Multicotizador en línea que permite cotizar y emitir online una amplia variedad de Seguros | Menor time-to-market para el roll-out de productos y nuevos deals. | Gestión de pólizas multi-ramo y Multi-compañia. | Registro de interacciones con clientes. | Liquidación de cobranzas y comisiones con gestión de redes comerciales. | Manejo de workflow de ventas con estados y cumplimiento de metas. | Asoramiento e implementación 360° de la estrategia competitiva.",

    },
    
]

export const getCardInfo = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info)
        }, 0)
    })
}