import React, { useEffect, useState } from 'react'
import { Carousel } from '../Carousel/Carousel'
import { ConsultingContainer } from './ConsultingContainer/ConsultingContainer'
import { ContactContainer } from '../Index/ContactContainer/ContactContainer'
import { getMailInfoByPage } from '../Index/ContactContainer/ContactForm/ContactData'

export const Consulting = () => {

  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("consulting").then(data => {
      setInfo(data)
    })
  }, [])

  return (


    <div className='mt-23' >
      <Carousel page="consulting" />
      <ConsultingContainer />
      <ContactContainer page={"consulting"} email_destino={info[0]?.email_destino}/>
    </div >
  )
}
