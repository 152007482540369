import React, { useState, useEffect } from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { SistemasContainer } from './SistemasContainer/SistemasContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const Sistemas = () => {

  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("sistemas").then(data => {
      setInfo(data)
    })
  }, [])

  return (

    <div className='mt-23'>
      <Carousel page="sistemas" />
      <SistemasContainer />
      <ContactContainer page={"sistemas"} email_destino={info[0]?.email_destino} />
    </div>
  )
}
