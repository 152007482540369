const info = [
    {
        id: '1',
        tittle: 'Seguros para empresas',
        items: `Caución,
        Incendio,
        ART,
        Industria,
        Responsabilidad Civil,
        Transporte,
        Comercios,
        Consorcio,
        Flotas,
        Agro,
        Prepaga Médica`,

    },
    {
        id: '2',
        tittle: 'Seguros para individuos',
        items: `Auto,
        Moto,
        AP,
        Embarcación,
        Prepaga Médica,
        Asistencia al viajero,
        Bolso Protegido,
        Celular,
        Hogar,
        Incendio`,
    },


]

export const getListInfo = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info)
        }, 0)
    })
}