import React,{useState,useEffect} from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { ItManagementContainer } from './ItManagementContainer/ItManagementContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const ItManagement = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("itmanagement").then(data => {
      setInfo(data)
    })
  }, [])
  return (
    <div className='mt-23'>
        <Carousel page="itmanagement"/>
        <ItManagementContainer/>
        <ContactContainer page={"itmanagement"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
