import React from 'react'
import imgItem from '../../../../img/insurance/productores/item.svg'

export const B2B2CItems = (props) => {

    let items = []
    if (props.items) {
        items = props.items.split('|');
    }
    return (
        <div className="col-span-3 lg:col-span-1">
            <h2 className="text-orange mb-2">{props.tittle}</h2>
            {items.map((item, index) =>
                <div key={index}>
                    <img src={imgItem} width="20px" className="inline-block align-top mr-2" alt='item' style={{marginTop:"4px"}}/>
                    <p className="inline-block leading-6 w-10/12 mb-4">{item}</p>
                    <br />
                </div>)}
        </div>
    )
}
