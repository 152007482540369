import insurance1 from '../../../img/insurance/solutions/solutions-1.png'
import insurance2 from '../../../img/insurance/solutions/solutions-2.png'

const info = [
    { 
        id: '1', 
        page: "b2b2c",
        img: insurance1,
        tittle:'B2B2C',
        text:"Trazamos soluciones estratégicas y tecnológicas a medida de nuestros clientes, las cuales les permitan proveer una serie de productos y servicios complementarios a su foco de actuación, asegurando el menor time-to-market para el roll-out de productos y nuevos deals."
    },
    { 
        id: '2', 
        page: "productores",
        img: insurance2,
        tittle:'Canal Productores',
        text:"Trabajamos constantemente en la innovación y desarrollo de herramientas tecnológicas que permiten a nuestros productores potenciar su desarrollo comercial, a través de nuestros sistemas y metodologías ágiles."
    },
]

export const getCardInfo = (page) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info)
        }, 0)
    })
}