import React from 'react'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
export const OperacionesContainer = () => {


  return (
    <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
      <Link to={"/tpa"} className="hover:font-bold text-orange">Volver</Link>
      <GenericInfo text="Nuestra línea de negocios AG TPA cuenta con profesionales capacitados para crear redes de servicios y cubrir los requerimientos técnicos que tengan los clientes de las empresas que nos eligen, de manera que ellas puedan concentrarse en las áreas esenciales de su actividad mientras nosotros manejamos las operaciones periféricas." />
    </section>
  )
}
