import React, { useEffect, useState } from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { EmpresaContainer } from './EmpresasContainer/EmpresaContainer'
import { ProductoresContainer } from './ProductoresContainer/ProductoresContainer'
import { ReinventateCard } from './ReinventateCard/ReinventateCard'
import { SumarteContainer } from './SumarteContainer/SumarteContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const Productores = () => {
    const [info, setInfo] = useState([])

    useEffect(() => {
        getMailInfoByPage("productores").then(data => {
            setInfo(data)
        })
    }, [])

    return (
        <div className='mt-23'>
            
            <Carousel page="productores" />
            <ProductoresContainer />
            <SumarteContainer />
            <EmpresaContainer />
            <ReinventateCard />
            <ContactContainer page={"productores"} email_destino={info[0]?.email_destino} />
        </div>
    )
}
