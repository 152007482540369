import React from 'react'

export const GenericInfo = (props) => {
    return (
        <>
            <p className="text-center my-8 lg:px-32">{props.text}</p>
            {props.text2 &&  <><br /><p className="text-center mb-12 lg:px-32">{props.text2}</p></>}
            <h1 className="text-center">{props.tittle}</h1>
        </>
    )
}
