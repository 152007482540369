import React,{useEffect,useState} from 'react'
import { Carousel } from '../Carousel/Carousel'
import { AboutUs } from './AboutUs/AboutUs'
import { ContactContainer } from './ContactContainer/ContactContainer'
import { ServicesContainer } from './ServicesContainer/ServicesContainer'
import { getMailInfoByPage } from './ContactContainer/ContactForm/ContactData'


export const Index = () => {

  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("index").then(data => {
      setInfo(data)
    })
  }, [])

  return (

    <div className='mt-23'>
        <Carousel page={"index"}/>
        <AboutUs/>
        <ServicesContainer/>
        <ContactContainer page={"index"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
