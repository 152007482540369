import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FinanzasInfo } from '../FinanzasInfo/FinanzasInfo'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'
export const FinanzasContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("finanzas").then(data => {
            setInfo(data)

        })

    }, [])

  return (
    <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
        <Link to={"/consulting"} className="hover:font-bold text-orange"> Volver</Link>
        <FinanzasInfo/>
        <div className="grid grid-cols-4 gap-8 my-8">
        {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
        </div>
    </section>
  )
}
