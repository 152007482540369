import React, { useState, useEffect } from 'react'

import HomeWho from '../../../img/home/home-who.png'
import HomeValores from '../../../img/home/home-valores.svg'
import { CardAboutUs } from './CardAboutUs/CardAboutUs'

import { getInfo } from './InfoAbout'

export const AboutUs = () => {
    const [info, setInfo] = useState([])
    const [mas, setMas] = useState(false)

    const masTexto = <ul className="ver-mas-texto mt-4 list-disc list-inside">
        <li>La <b>honestidad y la ética</b> legitiman el vínculo con
            nuestros clientes.</li>
        <li>El <b>profesionalismo</b> es nuestra garantía de calidad. Entendemos que el trabajo es aquel
            implica pensar soluciones de manera oportuna y eficiente.</li>
        <li>La <b>confidencialidad y la prudencia</b> rigen el uso de la información que manejamos de
            clientes.</li>
        <li>La <b>creatividad</b> signa nuestra búsqueda permanente de innovaciones y alternativas
            concretas.</li>
        <li>La <b>responsabilidad</b> es el estilo cuidado con el que pensamos e interactuamos con
            proveedores, clientes y con todo nuestro entorno.</li>
    </ul>


    useEffect(() => {
        getInfo().then(data => {
            setInfo(data)
        })
    }, [])


    return (
        <section id="us" className="px-4 lg:px-32 py-32 lg:py-32 bg-grey-2 text-grey">
            <div className="grid grid-cols-3 mb-8 mx-0 md:mx-32 lg:mx-0">
                <div className="col-span-3 lg:col-span-2 lg:pr-10 text-center lg:text-left">
                    <h1 className="mb-8">¿Quiénes somos?</h1>
                    <p>Somos una compañía formada por profesionales con acreditada experiencia en el mercado asegurador y
                        desarrollos tecnológicos afines a la industria. Ofrecemos servicios integrales en comercialización
                        de seguros, gestión de riesgos, consultoría financiera y administración general, asesoramiento y
                        participación en procesos estratégicos.<br /><br />
                        La solvencia técnica y académica de nuestros equipos de trabajo está respaldada por una trayectoria
                        de más de 35 años en importantes compañías nacionales e internacionales. Nos apasiona lo que
                        hacemos, por eso estamos permanentemente capacitándonos para brindar respuestas eficaces y que
                        generen valor en un mundo en constante cambio.<br /><br />
                        Operamos en el mercado argentino desde el año 2006 y nuestros servicios se extienden a toda
                        Latinoamérica, Norteamérica y Europa gracias a nuestras alianzas estratégicas en Brasil, en el Reino
                        Unido y en Estados Unidos.</p>
                </div>
                <div className="col-span-3 lg:col-span-1">
                    <img src={HomeWho} className="mx-auto" alt='' />
                </div>
            </div>
            <div className="grid grid-cols-3 gap-12 text-center lg:text-left mx-0 md:mx-32 lg:mx-0">
                {info.map(info =>
                    <CardAboutUs {...info} key={info.id} />
                )}
                <div className="col-span-3 lg:col-span-1">
                    <img width="80px" className="mx-auto lg:ml-0" src={HomeValores} alt='' />
                    <h2 className="mt-4 mb-2 text-orange">Valores</h2>
                    <p>En AG creemos que la confianza y la solidez son las bases para un vínculo profesional exitoso y
                        duradero. Nuestros esfuerzos se orientan a comprender en profundidad las necesidades puntuales de
                        nuestros clientes, por eso nuestros valores corporativos reflejan la dedicación con la que
                        desarrollamos nuestras tareas:</p>

                    {mas === false ? "" : masTexto}
                    <p className="text-orange cursor-pointer ver-mas" onClick={() => { setMas(!mas) }}>{mas === false ? "Ver más" : "Menos"}
                        <span id="ver-mas">
                            <svg className="chevron-down inline w-5 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d={mas === false ? "M19 9l-7 7-7-7" : "M5 15l7-7 7 7"} />
                            </svg>
                        </span>
                    </p>
                </div>
            </div>
        </section>
    )
}
