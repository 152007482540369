import React,{useEffect,useState} from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { B2B2Container } from './B2B2Container/B2B2Container'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'


export const B2b2c = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("b2b2c").then(data => {
      setInfo(data)
    })
  }, [])
  return (
    <div className='mt-23'>
      <Carousel page="b2b2c"/>
      <B2B2Container/>
      <ContactContainer page={"b2b2c"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
