import React, { useEffect, useState } from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { AdminGeneralContainer } from './AdminGeneralContainer/AdminGeneralContainer'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const AdminGeneral = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("admin-general").then(data => {
      setInfo(data)
    })
  }, [])
  return (

    <div className='col-23'>
      <Carousel page="admin-general" />
      <AdminGeneralContainer />
      <ContactContainer page={"admin-general"} email_destino={info[0]?.email_destino} />
    </div>
  )
}
