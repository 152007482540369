import React from 'react'
import FooterLogo from '../../img/home/footer-logo.svg'
import Brand1 from '../../img/home/brand-1.svg'
import Brand2 from '../../img/home/brand-2.svg'
import Brand3 from '../../img/home/brand-3.svg'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <footer className="px-4 lg:px-32 pb-16 py-8 lg:pb-16 bg-grey text-white">
        <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="col-span-1">
                <img src={FooterLogo} className="w-1/2 md:w-1/3 mx-auto lg:ml-0" alt='Footer Logo'/>
            </div>
            <div className="col-span-1 text-center lg:text-right mt-4">

                <Link className="text-xs block lg:inline lg:mx-4 mb-2" to={"/"}>Nosotros</Link>
                <Link className="text-xs block lg:inline lg:mx-4 mb-2" to={"/insurance-soluciones"}>Insurance Solutions</Link>
                <Link className="text-xs block lg:inline lg:mx-4 mb-2" to={"/consulting"}>Consulting</Link>
                <Link className="text-xs block lg:inline lg:mx-4 mb-2" to={"/tpa"}>TPA</Link>
                <br/><br/>
                {/* <a href="#t"><img className="inline-flex mx-1" width="15px" src={Brand1} alt='Twitter'/></a>
                <a href="#l"><img className="inline-flex mx-1" width="15px" src={Brand2} alt='LinkedIn'/></a>
                <a href="#f"><img className="inline-flex mx-1" width="10px" src={Brand3} alt='Facebook'/></a> */}
                <br/><br/>
                <p className="text-xs">Copyright 2022 AG Global. All rights reserved.</p>
            </div>
        </div>
    </footer>
  )
}
