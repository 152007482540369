const info = [
    {
        id: '1',
        page:'productores',
        tittle: 'Capacitación',
        text: `Ofrecemos permanentemente acceso a cursos de capacitación y formación profesional, para que puedas contar con todos los recursos que necesites y ampliar tu know how de la industria.`,
    },
    {
        id: '2',
        page:'productores',
        tittle: 'Estructura comercial',
        text: `Contamos con un equipo de profesionales dedicados para asesorarte, mantenerte al tanto de las novedades del mercado.`,
    },
    {
        id: '3',
        page:'productores',
        tittle: 'Acciones comerciales',
        text: `Trabajamos juntos para incrementar tu cartera mediante acciones y técnicas comerciales, a través de herramientas digitales, asesoramiento en marketing y estrategias de negocios.`,
    },
    {
        id: '4',
        page:'productores',
        tittle: 'Alianzas con las principales aseguradoras del mercado',
        text: `Te brindamos una amplia variedad de coberturas y compañías para incorporar a tu oferta.`,
    },
    {
        id: '5',
        page:'productores',
        tittle: 'Atención personalizada',
        text: `Soporte comercial, administrativo y de siniestros para nuestros productores y sus asegurados.`,
    },
    {
        id: '6',
        page:'productores',
        tittle: 'Customización',
        text: `Escuchamos, identificamos necesidades y ofrecemos propuestas integrales adaptadas a la necesidad de cada cliente.`,
    },
    {
        id: '7',
        page:'productores',
        tittle: 'Programa de fidelización',
        text: `A través de nuestro programa de puntos, accedé a beneficios exclusivos y convertite en partner premium.`,
    },
    {
        id: '8',
        page:'productores',
        tittle: 'Tecnología',
        text: `Herramienta web para administrar el negocio, aprovechar oportunidades comerciales y brindar altos niveles de servicio.`,
    },
    {
        id: '9',
        page:'finanzas',
        tittle: 'Inversión:',
        text: `Análisis y diseño de los activos reales (tangibles o intangibles) en los que la empresa debería invertir.`,
    },
    {
        id: '10',
        page:'finanzas',
        tittle: 'Financiamiento:',
        text: `Búsqueda de fondos para que la empresa pueda realizar las inversiones previstas.`,
    },
    {
        id: '11',
        page:'finanzas',
        tittle: 'Dividendos:',
        text: `Diseño de una política de retribución a accionistas acorde a la visión a largo plazo de la empresa.`,
    },
    {
        id: '12',
        page:'finanzas',
        tittle: 'Técnicas:',
        text: `Relacionadas a las elecciones operativas y financieras en el día a día.`,
    },
    {
        id: '13',
        page:'admin-general',
        tittle: 'Gestión contable:',
        text: `Elaboración del Estado de Situación Financiera, actualización y supervisión de procesos contables.`,
    },
    {
        id: '14',
        page:'admin-general',
        tittle: 'Gestión de tesorería:',
        text: `Elaboración del presupuesto anual, histórico o base cero; interpretación de las variables del presupuesto mensual contra el real para toma de decisiones; determinación del flujo diario de efectivo, en base al presupuesto anual autorizado y datos reales.`,
    },
    {
        id: '15',
        page:'admin-general',
        tittle: 'Gestión fiscal:',
        text: `Actualización y supervisión de procesos fiscales, elaboración de informes.`,
    },
    {
        id: '16',
        page:'admin-general',
        tittle: 'Gestión financiera:',
        text: `Análisis de la situación financiera de la empresa, proceso de planeamiento financiero, etc.`,
    },
    {
        id: '17',
        page:'admin-general',
        tittle: 'Gestión de créditos y cobranzas:',
        text: `Elaboración de las principales premisas que miden la correcta aplicación del crédito y obtención de la cobranza, por ejemplo: rotación de las cuentas por cobrar, rotación optima de las cuentas por cobrar, planeamiento de cobranza, clasificación por tipo de clientes, cuentas incobrables, etc.`,
    },
    {
        id: '18',
        page:'estrategia',
        tittle: 'Planificación estratégica:',
        text: `Una vez establecidos el marco y los objetivos, se formulan diversas estrategias posibles y se elige aquella que más se adecua al estilo de la organización y a sus aspiraciones. Se analiza y diseña también la estructura organizativa necesaria para alcanzar esa estrategia.`,
    },
    {
        id: '19',
        page:'estrategia',
        tittle: 'Definición de objetivos estratégicos:',
        text: `En función de la Misión de la compañía, se piensa la filosofía (es decir: el conjunto de valores) que enmarcarán las acciones. Luego, se establecer los objetivos indispensables a mediano y largo plazo para desarrollar los negocios presentes y alcanzar los negocios futuros que pretende la organización.`,
    },
    {
        id: '20',
        page:'estrategia',
        tittle: 'Ejecución estratégica:',
        text: `Análisis de la situación financiera de la empresa, proceso de planeamiento financiero, etc.`,
    },
    {
        id: '21',
        page:'marketing',
        tittle: 'BPO internos',
        text: `gestionamos los servicios de atención al cliente, de administración y finanzas, de procesos de abastecimiento y compras, de procesos de recursos humanos y capacitación.`,
    },
    {
        id: '22',
        page:'marketing',
        tittle: 'BPO customizados:',
        text: `diseñamos servicios de outsourcing específicos para procesos puntuales que requieren nuestros clientes.`,
    },
    {
        id: '23',
        page:'itmanagement',
        tittle: 'Inteligencia Competitiva:',
        text: `Aumentamos la probabilidad de éxito en la ejecución de las estrategias de nuestros clientes mediante el acceso a información relevante y el desarrollo de herramientas que analizan y estiman la reacción de los competidores ante los cambios`,
    },
    {
        id: '24',
        page:'itmanagement',
        tittle: 'Portales y gestión de contenidos:',
        text: `a organización de la información en tiempo y forma resulta indispensable para el desarrollo de las actividades cotidianas de la compañía.`,
    },
    {
        id: '25',
        page:'itmanagement',
        tittle: 'Data Management:',
        text: `El manejo, la clasificación y el almacenamiento de datos puede tener efectos críticos en la calidad y la consistencia de la información que utiliza la empresa para realizar sus actividades e intercambios con el entorno.`,
    },
    {
        id: '26',
        page:'itmanagement',
        tittle: 'Data Warehouse Governance:',
        text: `Ayudamos a nuestros clientes a definir el modelo que usarán para asegurar el uso y la recuperación óptima de la información, como también la aplicación de las políticas corporativas referidas a seguridad, al diseño técnico, etc.`,
    },
    {
        id: '27',
        page:'itmanagement',
        tittle: 'Data Structure:',
        text: `Diseñamos un modelo particular de categorizar y almacenar la información para cada cliente, en diversos sistemas.`,
    },
    {
        id: '28',
        page:'itmanagement',
        tittle: 'Data Architecture:',
        text: `Este servicio consta del diseño e implementación del circuito procesamiento de datos, su almacenamiento y utilización en un determinado sistema. Es decir: el flujo de datos de nuestros clientes.`,
    },
    {
        id: '29',
        page:'itmanagement',
        tittle: 'Master Data & Metadata:',
        text: `Consiste en la definición de la información de las operaciones claves del negocio: transacciones principales y sub-transacciones.`,
    },
    {
        id: '30',
        page:'itmanagement',
        tittle: 'Data Security:',
        text: `Este servicio tiene como objetivo proteger la información de nuestros clientes y los soportes físicos que la contienen.`,
    },
    {
        id: '31',
        page:'rrhh',
        tittle: 'Selección de personal por competencias:',
        text: `El objetivo es encontrar a los candidatos más adecuados a los requerimientos de los puestos de trabajo que ofrecen nuestros clientes, en función del modelo de competencias de la organización, es decir, de las habilidades y del perfil específico que se adecúa a los valores del cliente.`,
    },
    {
        id: '32',
        page:'rrhh',
        tittle: 'Plan de carreras:',
        text: `Diseñamos una matriz organizacional a través de la cual los Recursos Humanos de nuestros clientes sientan que pueden progresar, no solo como profesionales sino también como integrantes de un proyecto que los contempla`,
    },
    {
        id: '33',
        page:'rrhh',
        tittle: 'Capacitación:',
        text: `Desarrollamos Programas de Capacitación para lograr una correcta adecuación del personal al puesto de trabajo que desempeña, tanto en los niveles Gerenciales y Medios como en el Personal Operativo.`,
    },
    {
        id: '34',
        page:'rrhh',
        tittle: 'Evaluación del personal:',
        text: `Nuestros métodos de evaluación de colaboradores se orientan a obtener información que pueda registrarse, procesarse y canalizarse para mejorar el desempeño humano en las empresas de nuestros clientes.`,
    },
    {
        id: '35',
        page:'qhse',
        tittle: 'Diagnóstico:',
        text: `Identificación de los recursos existentes.`,
    },
    {
        id: '36',
        page:'qhse',
        tittle: 'Implementación:',
        text: `Realización de las políticas definidas y los procedimientos desarrollados.`,
    },

    {
        id: '37',
        page:'qhse',
        tittle: 'Compromiso:',
        text: `Capacitación y toma de conciencia en todos los niveles de la empresa de la importancia de la certificación.`,
    },

    {
        id: '38',
        page:'qhse',
        tittle: 'Auditorías Internas:',
        text: `El personal de la empresa realiza auditorías para detectar evidencias sobre incumplimientos en la documentación, en los registros o en el conocimiento del personal.`,
    },

    {
        id: '39',
        page:'qhse',
        tittle: 'Planeamiento:',
        text: `Definición de los tiempos de cada una de las actividades a realizar.`,
    },

    {
        id: '40',
        page:'qhse',
        tittle: 'Certificación:',
        text: `Visita de la compañía certificadora para evaluar el grado de cumplimiento del sistema de calidad.`,
    },

    {
        id: '41',
        page:'qhse',
        tittle: 'Documentación:',
        text: `Registro de cada una de las políticas de la organización y su manera de cumplir la norma en manuales.`,
    },

    {
        id: '42',
        page:'qhse',
        tittle: 'Visitas de Seguimiento:',
        text: `En esta etapa se realizan las inspecciones acordadas con el organismo certificador para garantizar la permanencia en la adopción de las normas.`,
    },
    {
        id: '43',
        page:'atencion-clientes',
        tittle: 'Call Center:',
        text: `En AG TPA proporcionamos distintas alternativas para asistir a los clientes de las empresas que nos eligen, ya sea a través de Call Center propios ó mediante la tercerización del servicio.`,
        text2: `Nuestros Call Center propios se encuentran equipados con tecnología de última generación y los profesionales seleccionados se desarrollan con un trato cordial y efectivo en la gestión de los requerimientos de los clientes, tanto a nivel nacional como internacional.`
    },
    {
        id: '44',
        page:'atencion-clientes',
        tittle: 'Servicios para Compañías de Seguros:',
        text: `Complementariamente al servicio de Atención al Cliente, los equipos de profesionales de AG TPA se especializan en la gestión de siniestros para todo tipo de programas de seguros. De esta forma, AG TPA brinda un asesoramiento integral a los clientes de las empresas que nos eligen.`,
    },
    {
        id: '45',
        page:'marketing-directo',
        tittle: 'Mailing:',
        text: `Consiste en enviar por correo una carta o paquete personalizado a un grupo de personas específico, cuyas direcciones están en la base de datos de nuestros clientes.`,
    },
    {
        id: '46',
        page:'marketing-directo',
        tittle: 'Buzoneo:',
        text: `Esta herramienta consiste en hacer llegar a todos los domicilios un folleto especialmente diseñado (con anuncios de promociones, descuentos, catálogos de establecimiento, regalos, etc.) para conseguir una respuesta masiva e inmediata.`,
    },
    {
        id: '47',
        page:'marketing-directo',
        tittle: 'Cuponing:',
        text: `La entrega de cupones con descuentos o beneficios para los clientes ha demostrado ser una herramienta muy eficaz en la estimulación de las ventas.`,
    },
    {
        id: '48',
        page:'marketing-directo',
        tittle: 'Telemarketing::',
        text: `En AG TPA hemos desarrollado aplicaciones y programas especializados para dar respuesta, de forma eficiente y dinámica, a todas las dudas o gestiones que se puedan realizar de forma remota a través de las líneas telefónicas.`,
    },
    {
        id: '49',
        page:'marketing-directo',
        tittle: 'Presencia en mercados virtuales:',
        text: `El Marketing Directo en sitios de compra y venta en Internet permite acercar el producto a los clientes sin generar grandes costos.`,
    },
    {
        id: '50',
        page:'capacitacion',
        tittle: 'Análisis y Diagnóstico:',
        text: `Este paso determina las necesidades de capacitación mediante la identificación de problemas (observación, contacto directo y presentación de casos), el análisis de documentos y la elaboración de un diagnóstico.`,
    },
    {
        id: '51',
        page:'capacitacion',
        tittle: 'Diseño e Implementación:',
        text: `Esta etapa consiste en transformar la percepción de aquella necesidad de capacitación en un sistema de acción concreto. Para esto elaboramos un proyecto que se presenta a la Gerencia y, una vez consensuado, se establecen los criterios de elección de los participantes y el plan de acción.`,
    },
    {
        id: '52',
        page:'capacitacion',
        tittle: 'Entrenamiento y Coaching:',
        text: `En esta etapa se pone en marcha el proyecto. AG TPA coordinar las actividades y crear las condiciones para su óptimo desarrollo.`,
    },
    {
        id: '53',
        page:'capacitacion',
        tittle: 'Seguimiento y Evolución:',
        text: `En esta etapa se miden los resultados del Programa y se toman ideas para futuros Programas de capacitación.`,
    },
    





]

export const getItemInfoByPage = (page) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info.filter(item => item.page === page))
        }, 0)
    })
}