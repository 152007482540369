import React,{useEffect,useState} from 'react'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'

export const MarketingContainer = () => {
    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("marketing").then(data => {
            setInfo(data)

        })

    }, [])

  return (
    <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
        <Link to={"/consulting"} className="hover:font-bold text-orange">Volver</Link>
        <GenericInfo text="El equipo de AG Consulting ofrece servicios de marketing estratégico para identificar y reconocer las necesidades actuales y futuras de nuestros clientes, localizar nuevos nichos de mercado, identificar segmentos de mercado potenciales, valorar el potencial e interés de esos mercados, orientar a la empresa en busca de esas oportunidades y diseñar un plan de actuación que alcance los objetivos." tittle="Nuestros productos y servicios"/>
        <div className="grid grid-cols-2 gap-8 my-8">
        {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
        </div>
    </section>
  )
}
