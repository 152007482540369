import React, { useState, useEffect } from 'react'
import { B2B2CInfo } from '../B2B2CInfo/B2B2CInfo'
import { getCardInfo } from '../B2B2Card/B2B2CardData'
import { B2B2Card } from '../B2B2Card/B2B2Card'
import { getItemsInfo } from '../B2B2CItems/B2B2CItemsData'
import { B2B2CItems } from '../B2B2CItems/B2B2CItems'
import { B2B2CMoreInfoCard } from '../B2B2CMoreInfoCard/B2B2CMoreInfoCard'

export const B2B2Container = () => {

    const [info, setInfo] = useState([])
    const [items, setItems] = useState([])
    const [moreInfo, setMoreInfo] = useState()
    const [activeInfo, setActiveInfo] = useState(false)
    const [clases, setClases] = useState("text-white bg-orange iconClicked")
    const [idActive, setIdActive] = useState()

    useEffect(() => {
        getCardInfo().then(data => {
            setInfo(data)
        })
        getItemsInfo().then(data => {
            setItems(data)
        })


    }, [])

    const VerMasInfo = (id) => {
        if (activeInfo === false) {
            setMoreInfo(info.filter(item => item.id === id))
            setActiveInfo(true)
            setIdActive(id)
            setClases("text-white bg-orange iconClicked")
        }
        else {
            if (id !== idActive) {
                setMoreInfo(info.filter(item => item.id === id))
                setActiveInfo(true)
                setIdActive(id)
                setClases("text-white bg-orange iconClicked")
            }
            else
            {
                setMoreInfo("")
                setActiveInfo(false)
                setClases("")
            }
        }
    }

    return (
        <section id="how" className="px-4 lg:px-32 py-8 text-grey" >
            <B2B2CInfo />
            <div className="flex text-center md:px-32 justify-between md:gap-8 my-16" >
                {info.map(card => <B2B2Card {...card} key={card.id} onClick={() => { VerMasInfo(card.id) }} clase={card.id === idActive ? clases : ""} />)}
            </div>
            {moreInfo ? <B2B2CMoreInfoCard {...moreInfo[0]} /> : null}
            <h1 className="text-center my-8">Nuestros insights competitivos</h1>
            <div className="grid grid-cols-3 mx-0 md:mx-32 lg:mx-0">
                {items.map(item => <B2B2CItems {...item} key={item.id} />)}
            </div>
        </section>
    )
}
