import React from 'react'
import HeaderLogo from '../../img/home/header-logo.svg'
import HeaderBrasil from '../../img/home/header-brasil.svg'
import HeaderArgentina from '../../img/home/header-argentina.svg'
import HeaderUSA from '../../img/home/header-eeuu.svg'
import { Link } from 'react-router-dom'
import { useState } from 'react'


export const NavBar = () => {

    const [open, setOpen] = useState("")
    const [hamburguer, setHamburguer] = useState("")
    const [cross, setCross] = useState("hidden")

    const handleOpen = () => {
        if (open === "show") {
            setOpen("")
            setHamburguer("")
            setCross("hidden")
        } else {
            setOpen("show")
            setHamburguer("hidden")
            setCross("")
        }
    }

    return (
        <header className="fixed bg-white top-0 left-0 right-0 z-50 text-grey">
            <nav className="relative w-full flex flex-wrap items-center justify-between py-4 shadow-lg navbar navbar-expand-lg navbar-light">
                <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
                    <Link className="flex items-center mt-2 lg:mt-0 mr-1" to="/">
                        <img src={HeaderLogo} width="180px" className="lg:ml-16 logo" alt='Header Logo' />
                    </Link>
                    <button  data-collapse-toggle="mobile-menu" type="button" onClick={() => { handleOpen() }}  className="hamburguer inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" 
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                        <span className="sr-only">Open main menu</span>
                        <svg className={"hamburguer w-6 h-6 " + hamburguer} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                        <svg className={"cross w-6 h-6 " + cross} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                    <div className={"collapse navbar-collapse flex-grow items-center " + open}  id="navbarSupportedContent">
                        <div className="mx-auto"></div>
                        <ul className="navbar-nav flex flex-col pl-0 list-style-none mr-0">
                            <li className="nav-item lg:p-2">
                                <Link to="/" onClick={() => { handleOpen() }} className="nav-link inline-block align-middle text-md border-b-2 border-white hover:border-grey px-4 pt-3">Nosotros</Link>
                            </li>
                            <li className="nav-item dropdown lg:p-2">
                                <Link to="/insurance-soluciones" onClick={() => { handleOpen() }} className="nav-link inline-block align-middle text-md border-b-2 border-white hover:border-grey px-4 pt-3">
                                    Insurance Solutions
                                </Link>
                                <ul className="dropdown-menu hidden bg-grey-2" id="insurance">
                                    <li>
                                        <Link to="/insurance-soluciones/b2b2c" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            B2B2C
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/insurance-soluciones/productores" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Canal de productores
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown lg:p-2">
                                <Link to="/consulting"  onClick={() => { handleOpen() }} className="nav-link inline-block align-middle text-md border-b-2 border-white hover:border-grey px-4 pt-3">
                                    Consulting
                                </Link>
                                <ul className="dropdown-menu hidden bg-grey-2" id="consulting">
                                    <li>
                                        <Link to="/consulting/finanzas" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Finanzas corporativas
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/consulting/admin-general" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Administración general
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/consulting/estrategia" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Estrategia & ejecución
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/consulting/marketing" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Marketing
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/consulting/it-management" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            IT Management
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/consulting/rrhh" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Recursos Humanos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/consulting/qhse" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Implementación Sistemas de Gestión QHSE
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown lg:p-2">
                                <Link to="/tpa" onClick={() => { handleOpen() }} className="nav-link inline-block align-middle text-md border-b-2 border-white hover:border-grey px-4 pt-3">TPA</Link>
                                <ul className="dropdown-menu hidden bg-grey-2" id="tpa">
                                    <li>
                                        <Link to="/tpa/atencion-clientes" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Atención al cliente
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/tpa/operaciones" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Operaciones
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/tpa/marketing-directo" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Marketing Directo
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/tpa/capacitacion" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Capacitación
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/tpa/sistemas" className="dropdown-item text-md py-1 px-4 block hover:bg-grey hover:text-white">
                                            Sistemas
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item p-1 lg:p-2">
                                <a className="nav-link" href="#a">
                                    <img className="inline-block mt-3 ml-4 lg:ml-0" src={HeaderUSA} width="20px" alt='USA' />
                                </a>
                                <a className="nav-link" href="#a">
                                    <img className="inline-block mt-3 ml-2 lg:ml-0" src={HeaderArgentina} width="20px" alt='Argentina' />
                                </a>
                                <a className="nav-link" href="#a">
                                    <img className="inline-block mt-3 ml-2 lg:ml-0" src={HeaderBrasil} width="20px" alt='Brasil' />
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
        </header>
    )
}


