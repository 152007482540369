import React from 'react'
import ContactLocation from '../../../../img/home/home-contact-location.svg'
import ContactPhone from '../../../../img/home/home-contact-phone.svg'
import ContactMail from '../../../../img/home/home-contact-mail.svg'


export const ContactInfo = () => {
    return (
        <>
        <h1 className="mb-8">Contacto</h1>
            <div className="grid grid-cols-3 lg:px-32 mb-4  mx-0 md:mx-32 lg:mx-0">
                <div className="col-span-3 lg:col-span-1 mb-2 mx-8 lg:mx-0 text-left lg:text-center">
                    <img className="inline-block align-top mr-2" width="20px" src={ContactLocation} alt='' />
                    <p className="text-md inline-block text-left">Av. Bartolomé Mitre 1249<br />
                        P.B. "B" (C.P 1604), Florida Oeste,<br />
                        Buenos Aires, Argentina.
                    </p>
                </div>
                <div className="col-span-3 lg:col-span-1 mb-2 mx-8 lg:mx-0 text-left lg:text-center">
                    <img className="inline-block align-top mr-2" width="20px" src={ContactPhone} alt='' />
                    <p className="text-md inline-block">Tel: +54 11 4703 9500</p>
                </div>
                <div className="col-span-3 lg:col-span-1 mb-2 mx-8 lg:mx-0 text-left lg:text-center">
                    <img className="inline-block align-top mt-2 mr-2" width="20px" src={ContactMail} alt='' />
                    <p className="text-md inline-block">info@agglobalservices.com.ar</p>
                </div>
            </div>
        </>
    )
}
