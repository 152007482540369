import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'

export const AdminGeneralContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("admin-general").then(data => {
            setInfo(data)

        })

    }, [])

  return (
    <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
        <Link to={"/consulting"} className="hover:font-bold text-orange"> Volver</Link>
        <GenericInfo text="Los procesos de administración y gestión contable son la estructura técnica que aproxima la situación actual al cumplimiento de las metas, custodiando el desarrollo eficiente y racional de los procesos del día a día de la empresa." tittle="Nuestros productos y servicios"/>
        <div className="grid grid-cols-2 gap-8 my-8">
        {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
        </div>
    </section>
  )
}
