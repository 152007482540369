

const info = [
    { 
        id: '1', 
        page: "finanzas",
        tittle:'Finanzas Corporativas >',
    },
    { 
        id: '2', 
        page: "it-management",
        tittle:'IT Management >',
    },
    { 
        id: '3', 
        page: "admin-general",
        tittle:'Administración General >',
    },
    { 
        id: '4', 
        page: "rrhh",
        tittle:'Recursos Humanos >',
    },
    { 
        id: '5', 
        page: "estrategia",
        tittle:'Estrategia & Ejecución >',
    },
    { 
        id: '6', 
        page: "qhse",
        tittle:'Implementación Sistemas de Gestión QHSE >',
    },
    { 
        id: '7', 
        page: "marketing",
        tittle:'Marketing >',
    },
]

export const getAreaInfo = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info)
        }, 0)
    })
}