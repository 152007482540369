import React from 'react'
import item from '../../img/insurance/productores/item.svg'

export const GenericItemInfo = (props) => {
    return (
        <div>
            <div className='flex'>
                <div className='flex align-top'>
                    <div>
                        <img src={item} width="20px" className="mr-2 mt-2" alt='item' style={{minWidth:"20px"}} />
                    </div>
                </div>
                <h2 className="text-orange text-md">{props.tittle}</h2>
            </div>
            <p className="ml-10" style={{ wordBreak: "break-word" }}>{props.text}</p>
            {props.text2 && <><br /><p className="ml-10">{props.text2}</p><br /></>}
        </div>
    )
}
