import React from 'react'
import itemImg from '../../../../img/insurance/productores/item.svg'

export const B2B2CMoreInfoCard = (props) => {
  let moreItems1 = []
  if (props.moreItems) {
    moreItems1 = props.moreItems.split('|');
  }

  return (



    <div className="solutions-details lg:px-32 my-8 mx-0 md:mx-32 lg:mx-0">
            <p className="">{props.moreText1}</p>
            <br/>
            <br/>
            <p className="mb-8">{props.moreText2}</p>
            <h3 className="text-orange mb-4"> ¿Por qué elegirnos?</h3>
            {moreItems1.map((item,index) => <p><img src={itemImg} width="20px" className="inline mr-4" alt='item'/>{item}</p>)}
        </div>
  )
}
