import React from 'react'

export const ProductoresList = (props) => {
    let items = []
    if (props.items) {
        items = props.items.split(',');
    }

    return (
        <div className="col-span-2 lg:col-span-1">
            <p id="empresas" className="seguros text-orange text-center">{props.tittle}</p>
            <div className='flex justify-center'>
                <ul id="empresas" className="text-left lg:px-20 list-disc list-inside ul-2col">
                    {items.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
            </div>
        </div>
    )
}
