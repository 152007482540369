
import HomeVision from '../../../img/home/home-vision.svg'
import HomeMision from '../../../img/home/home-mision.svg'
// import HomeValores from '../../../img/home/home-valores.svg'

const info = [
    { 
        id: '1', 
        icon: HomeMision,
        tittle:'Misión',
        text:'Brindar servicios integrales apoyados en la tecnología que contribuyan al éxito de nuestros clientes afines a la industria aseguradora y a otras, ofreciendo una gama de soluciones específicas para el cumplimiento de sus estrategias y el incremento de su rentabilidad en el tiempo, brindándoles tranquilidad y confianza en todo momento.'
    },
    { 
        id: '2', 
        icon: HomeVision,
        tittle:'Visión',
        text:'Ser una compañía líder en el mercado regional orientada a satisfacer las exigencias de nuestros clientes brindando soluciones integrales innovadoras, competitivas y eficientes. Distinguirnos por encontrar la solución que cada uno de nuestros clientes necesita.'

    },
    // { 
    //     id: '1', 
    //     icon: HomeValores,
    //     tittle:'Valores',
    //     text:'En AG creemos que la confianza y la solidez son las bases para un vínculo profesional exitoso y duradero. Nuestros esfuerzos se orientan a comprender en profundidad las necesidades puntuales de nuestros clientes, por eso nuestros valores corporativos reflejan la dedicación con la que desarrollamos nuestras tareas:'

    // },
  ]

export const getInfo = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info)
        }, 0)
    })
}
