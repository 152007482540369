import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'


export const ItManagementContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("itmanagement").then(data => {
            setInfo(data)

        })

    }, [])
    return (
        <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
            <Link to={"/consulting"} className="hover:font-bold text-orange">Volver</Link>
            <GenericInfo text="En AG Consulting contamos con profesionales expertos en el diseño, desarrollo, integración de pruebas y administración de sistemas que ofrecen soluciones integrales de tecnología (end-to-end) para nuestros clientes." text2="De esta forma garantizamos la disponibilidad y la fiabilidad de la información necesaria y valiosa para la toma de decisiones y la identificación de oportunidades." tittle="Nuestros productos y servicios" />
            <div className="grid grid-cols-2 gap-8 my-8">
                {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
            </div>
        </section>
    )
}
