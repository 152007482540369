import React from 'react'

export const B2B2Card = (props) => {

    const changeClass = () => {
        props.onClick()
    }

    return (
        <div  className={`solutions icon cursor-pointer lg:w-1/3 bg-grey-2 rounded-3xl shadow-xl text-orange icon res `+ props.clase} onClick={changeClass} id={props.id}>
            {props.children}
            <h4 className="text-md md:text-lg mb-2 res-tit ">{props.tittle}</h4>
            <svg className="chevron-down inline w-5 mb-6 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
        </div>
        
    )
}
