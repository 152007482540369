import React, { useState, useEffect } from 'react'
import { EmpresasImg } from './EmpresasImg/EmpresasImg'
import { getEmpresaInfo } from './EmpresasImg/EmpresasImgData'
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export const EmpresaContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getEmpresaInfo().then(data => {
            setInfo(data)
        })
    }, [])

    const settings = {
        container: ".marcas",
        loop: true,
        autoplay: true,
        autoplayTimeout: 1500,
        swipeAngle: false,
        speed: 1800,
        controls: false,
        autoplayButtonOutput: false,
        responsive: {
            320: {
                "edgePadding": 20,
                "gutter": 20,
                "items": 2
            },
            700: {
                "items": 3,
                "gutter": 30
            },
            900: {
                "items": 5
            }
        }
    };

    

    return (
        <section id="empresas" className="px-4 lg:px-32 py-8 lg:py-16 text-grey">
            <h1 className="mb-4 text-center">Empresas con las que trabajamos</h1>
            <hr className="hr border-orange m-auto" />
            <div className="marcas">
                <TinySlider settings={settings}>
                    {info.map(item => <EmpresasImg {...item} key={item.id} />)}
                </TinySlider>
            </div>
        </section>
    )
}
