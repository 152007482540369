import React,{useState,useEffect} from 'react'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'

export const RRHHContainer = () => {
    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("rrhh").then(data => {
            setInfo(data)

        })

    }, [])
    return (
        <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
            <Link to={"/consulting"} className="hover:font-bold text-orange">Volver</Link>
            <GenericInfo text="En AG Consulting consideramos que los Recursos Humanos son la piedra angular del éxito de nuestros clientes, por lo cual ofrecemos servicios integrales en la gestión del factor humano. Los asistimos con soluciones innovadoras orientadas a optimizar la gestión de talentos, diseñar una estructura organizacional acorde, mejorar los procesos y usar de forma eficiente la tecnología." tittle="Nuestros productos y servicios" />
            <div className="grid grid-cols-4 gap-8 my-8">
                {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
            </div>
        </section>
    )
}
