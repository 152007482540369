import React from 'react'

export const ProductoresInfo = () => {
    return (
        <div className="col-span-5 lg:col-span-4 lg:pr-32 text-center">
            <p>Trabajamos constantemente en la innovación y desarrollo de herramientas tecnológicas que permiten a nuestros productores potenciar su desarrollo comercial, a través de nuestros sistemas y metodologías ágiles.<br /><br />
                Desarrollamos un front-end totalmente parametrizable especializado en canales de distribución digitales, en líneas personales, corporativas y affinity. Incluye también aplicaciones y portales de self- service para productores.</p>
            <h1 className="pt-8">¿Cómo lo hacemos?</h1>
            <p className="py-4 lg:px-32">
                Identificamos necesidades y oportunidades de crecimiento, co-creamos soluciones específicas para potenciar tu desarrollo. Nuestro esquema de micro-sistemas permite contratar únicamente las funcionalidades claves para optimizar la rentabilidad de tu empresa
            </p>
        </div>
    )
}
