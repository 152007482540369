
import HomeInsurance from '../../../img/home/home-insurance.png'
import HomeConsulting from '../../../img/home/home-consulting.png'
import HomeTpa from '../../../img/home/home-tpa.png'

const info = [
    { 
        id: '1', 
        img: HomeInsurance,
        tittle:'Insurance Solutions',
        text:`Diseñamos, implementamos y gestionamos propuestas integrales e innovadoras para la comercialización
        de seguros con apoyo en desarrollos tecnológicos afines a la industria. Nuestras herramientas son
        creadas 100% inhouse con la flexibilidad para proveer soluciones B2B2C, integradas y sincronizadas
        en todas las fases del negocio, facilitando la dinámica de comunicación con los sistemas de nuestros
        clientes.`,
        path:'/insurance-soluciones'
        
    },
    { 
        id: '2', 
        img: HomeConsulting,
        tittle:'Consulting',
        text:`Nuestro equipo de profesionales expertos en diversas disciplinas brindan consultoría dedicada sobre
        materias como nuevas tecnologías, desarrollo de estrategias y liderazgo, entre otras actividades
        esenciales de la empresa, que con el día a día, suelen perder su valor estratégico por desdibujarse
        tras la gestión inminente de los cambios.`,
        path:'/consulting'

    },
    { 
        id: '3', 
        img: HomeTpa,
        tittle:'TPA',
        text:` A través de la modalidad de trabajo Third Party Administration, nos encargamos de proveer servicios
        en nombre de las empresas que nos eligen para el trato con sus clientes; administración de reclamos,
        suscripciones y bajas, capacitaciones varias, entre otros, asegurando en todo momento la eficiencia
        en las operaciones.`,
        path:'/tpa'

    },
  ]

export const getInfoServices = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info)
        }, 0)
    })
}
