import React from 'react'

export const Tpainfo = () => {
  return (
    <p className="text-center">Nuestro equipo de especialistas diseña, desarrolla y administra procesos para que
            nuestros clientes se vean beneficiados por la optimización y la eficiencia que proporciona la tercerización
            de servicios suplementarios, entre ellos: el trato con sus clientes (administración de reclamos,
            suscripciones y bajas), el servicio de correo, la capacitación en procesos comerciales, y muchos más.</p>
  )
}
