import React, { useState, useEffect } from 'react'
import { ProductoresInfo } from '../ProductoresInfo/ProductoresInfo'
import { ProductoresList } from '../ProductoresList/ProductoresList'
import { getListInfo } from '../ProductoresList/ProductoresListData'

export const ProductoresContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getListInfo().then(data => {
            setInfo(data)

        })

    }, [])

    return (
        <section id="soluciones" className="px-4 lg:px-32 py-8 lg:py-16 mx-0 md:mx-32 lg:mx-0 text-grey">
            <ProductoresInfo />
            <div className="grid grid-cols-2">
                {info.map(li => <ProductoresList {...li} key={li.id} />)}
            </div>
        </section>
    )
}
