import React,{useState,useEffect} from 'react'
import { CardServices } from './CardServices/CardServices'
import { getInfoServices } from './InfoServices'


export const ServicesContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getInfoServices().then(data => {
            setInfo(data)
        })
    }, [])

  return (
    <section id="services" className="px-4 lg:px-32 pb-8 lg:pb-16  mx-0 md:mx-32 lg:mx-0 text-grey" >
    <h1 className="text-center my-8">Nuestros servicios</h1>
    <div className="grid grid-cols-3 gap-12">
    {info.map(info =>
                    <CardServices {...info} key={info.id}/>
                )}
    </div>
</section>
  )
}
