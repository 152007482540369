import React from 'react'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'

export const SistemasContainer = () => {
    return (
        <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
            <Link to={"/tpa"} className="hover:font-bold text-orange">Volver</Link>
            <GenericInfo text="Las tecnologías de información resultan hoy indispensables para dar soporte y volver más eficiente el desarrollo de una estrategia competitiva. En AG TPA hemos diseñado y desarrollado un Sistema Integral que permite la administración de las actividades mediante interfases entre los diferentes módulos que integran una compañía. Estos módulos son versátiles, ya que se adaptan a las necesidades de las empresas que nos eligen." />
        </section>
    )
}
