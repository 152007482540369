import React from 'react'
import HeaderLogo from '../../img/home/header-logo.svg'

export const NotFound = () => {

    return (
        <div className='header-margin text-center vh-100 flex flex-col justify-center '>
            <h1>Nuestra Culpa!</h1>
            <p className='mt-3'>La página que estás buscando no se encuentra disponible actualmente</p>
            <p>Porfavor inténtalo nuevamente y si el problema persiste contáctate con nosotros!</p>
            <div className="flex justify-center mt-8">
                <img src={HeaderLogo} alt="Logo" width={"400px"} />
            </div>
        </div>
    )
}
