import React, { useState, useEffect } from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { MarketingDirectoContainer } from './MarketingDirectoContainer/MarketingDirectoContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const MarketingDirecto = () => {

  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("marketing-directo").then(data => {
      setInfo(data)
    })
  }, [])

  return (
    <div className='mt-23'>
      <Carousel page="marketing-directo" />
      <MarketingDirectoContainer />
      <ContactContainer page={"marketing-directo"} email_destino={info[0]?.email_destino} />
    </div>
  )
}
