import React from 'react'
import { Link } from 'react-router-dom'

export const ConsultingArea = (props) => {

  return (
    <div className="col-span-1">
      <Link to={props.page}><p className="px-2 bg-grey hover:bg-orange cursor-pointer text-white text-center py-4"><b>{props.tittle}</b></p></Link>
    </div>
  )
}
