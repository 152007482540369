import React, { useState, useEffect } from 'react'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
export const SumarteContainer = () => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("productores").then(data => {
            setInfo(data)

        })

    }, [])
    return (
        <section id="sumarte" className="px-4 lg:px-32 py-8 lg:py-16 bg-grey-2 text-grey">
            <h1 className="mb-4 mx-0 md:mx-32 lg:mx-0">¿Por qué sumarte?</h1>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 mx-0 md:mx-32 lg:mx-0">
                {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
            </div>
        </section>
    )
}
