import React,{useEffect,useState} from 'react'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'

export const QHSEContainer = () => {
    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("qhse").then(data => {
            setInfo(data)

        })

    }, [])
    return (
        <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
            <Link to={"/consulting"} className="hover:font-bold text-orange">Volver</Link>
            <GenericInfo text="Mediante el cumplimiento de las pautas y patrones que establece la Internacional Organization for Strandarization (IOS), nuestros clientes logran implementar un sistema de gestión y aseguramiento de la calidad en el desarrollo de sus procesos reconocido a nivel internacional. Esto les proporciona un sistema de garantía de calidad, pues se transforma en el marco de confianza de los productos y servicios que brindan."  tittle="Metodología" />
            <div className="grid grid-cols-4 gap-8 my-8">
                {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
            </div>
        </section>
    )
}
