import React,{useState,useEffect} from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { RRHHContainer } from './RRHHContainer/RRHHContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const RRHH = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("rrhh").then(data => {
      setInfo(data)
    })
  }, [])
  return (
    <div className='mt-23'>
        <Carousel page="rrhh"/>
        <RRHHContainer/>
        <ContactContainer page={"rrhh"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
