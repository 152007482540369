import React, { useState, useEffect } from 'react'
import { Carousel } from '../Carousel/Carousel'
import { TpaContainer } from './TpaContainer/TpaContainer'
import { ContactContainer } from '../Index/ContactContainer/ContactContainer'
import { getMailInfoByPage } from '../Index/ContactContainer/ContactForm/ContactData'

export const Tpa = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("tpa").then(data => {
      setInfo(data)
    })
  }, [])
  
  return (
    <div className='mt-23'>
      <Carousel page="tpa" />
      <TpaContainer />
      <ContactContainer page={"tpa"} email_destino={info[0]?.email_destino} />
    </div>
  )
}
