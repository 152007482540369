import React,{useState,useEffect} from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { OperacionesContainer } from './OperacionesContainer/OperacionesContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const Operaciones = () => {

  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("operaciones").then(data => {
      setInfo(data)
    })
  }, [])

  return (

    <div className='mt-23'>
        <Carousel page="operaciones"/>
        <OperacionesContainer/>
        <ContactContainer page={"operaciones"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
