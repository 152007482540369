import  slider1 from '../../img/home/slider-1.jpg'
import  slider2 from '../../img/home/slider-2.jpg'
import  slider3 from '../../img/home/slider-3.jpg'
import  slider4 from '../../img/home/slider-4.jpg'
import insurance1 from '../../img/insurance/solutions/slider-1.jpg'
import consulting1 from '../../img/consulting/slider-1.jpg'
import tpa1 from '../../img/tpa/slider.jpg'
import b2b2c1 from '../../img/insurance/b2b2c/slider-1.jpg'
import productores from '../../img/insurance/productores/slider-2.jpg'
import finanzas from '../../img/consulting/finanzas-jumbotron.jpg'
import adminGeneral from '../../img/consulting/administracion-jumbotron.jpg'
import estrategia from '../../img/consulting/estrategia-jumbotron.jpg'
import marketing from '../../img/consulting/marketing-jumbotron.jpg'
import itmanagement from '../../img/consulting/itmanagement-jumbotron.jpg'
import rrhh from '../../img/consulting/rrhh-jumbotron.jpg'
import qhse from '../../img/consulting/qhse-jumbotron.jpg'
import atencionClientes from '../../img/tpa/atencion.jpg'
import operaciones from '../../img/tpa/operaciones.jpg'
import marketingDirecto from '../../img/tpa/marketing.jpg'
import capacitacion from '../../img/tpa/capacitacion.jpg'
import sistemas from '../../img/tpa/sistemas.jpg'

const info = [
    { 
        id: '1', 
        page: "index",
        img: slider1,
        tittle:'Potenciá tu negocio',
        text1:"Propuestas customizadas e innovadoras para la",
        text2:"comercialización masiva de Seguros.",
        to:"/insurance-soluciones/b2b2c"
    },
    { 
        id: '2', 
        page: "index",
        img: slider2,
        tittle:'Canal Productores',
        text1:"Soluciones integrales para gestionar,",
        text2:"administrar y potenciar tu cartera.",
        to:"/insurance-soluciones/productores"
    },
    { 
        id: '3', 
        page: "index",
        img: slider3,
        tittle:'Asesorate con expertos de la industria en múltiples disciplinas',
        text1:"Diseñamos y ejecutamos planes de acción para",
        text2:"fortalecer la estrategia corporativa de tu empresa.",
        to:"/consulting"

    },
    { 
        id: '4', 
        page: "index",
        img: slider4,
        tittle:'Fortalecé tu estructura',
        text1:"Proveemos servicios complementarios",
        text2:"para optimizar tus esfuerzos.",
        to:"/tpa"
    },
    { 
        id: '1', 
        page: "insurance-soluciones",
        img: insurance1,
        tittle:'Potenciá tu negocio',
        text1:"Propuestas customizadas e innovadoras para la",
        text2:"comercialización masiva de Seguros.",
        to: "/insurance-soluciones/b2b2c"
    },
    { 
        id: '2', 
        page: "insurance-soluciones",
        img: slider2,
        tittle:'Canal de Productores',
        text1:"Trabajamos constantemente en la innovación",
        text2:"y desarrollo de herramientas tecnológicas.",
        to: "/insurance-soluciones/productores"
    },
    { 
        id: '1', 
        page: "consulting",
        img: consulting1,
        tittle:'Consulting',
        text1:"El ambiente de negocios actual presenta un flujo",
        text2:"constante tanto de retos como de oportunidades."
    },
    { 
        id: '1', 
        page: "tpa",
        img: tpa1,
        tittle:'TPA',
        text1:"Nuestro equipo de especialistas diseña,",
        text2:"desarrolla y administra procesos."
    },
    { 
        id: '1', 
        page: "b2b2c",
        img: b2b2c1,
        tittle:'Business to Business to Consume',
        text1:"",
        text2:""
    },
    { 
        id: '1', 
        page: "productores",
        img: productores,
        tittle:'Canal de Productores',
        text1:"Trabajamos constantemente en la innovación",
        text2:"y desarrollo de herramientas tecnológicas."
    },
    { 
        id: '1', 
        page: "finanzas",
        img: finanzas,
        tittle:'FINANZAS CORPORATIVAS',
        text1:"Las Finanzas Corporativas se orientan a construir",
        text2:"mecanismos para que las empresas puedan crear valor."
    },
    { 
        id: '1', 
        page: "admin-general",
        img: adminGeneral,
        tittle:'ADMINISTRACIÓN GENERAL',
        text1:"En AG Consulting consideramos que la ejecución",
        text2:"es el vínculo entre las aspiraciones y los resultados."
    },
    { 
        id: '1', 
        page: "estrategia",
        img: estrategia,
        tittle:'ESTRATEGIA Y EJECUCIÓN',
        text1:"",
        text2:""
    },
    { 
        id: '1', 
        page: "marketing",
        img: marketing,
        tittle:'MARKETING ESTRATÉGiCO',
        text1:"El éxito de cualquier empresa depende, fundamentalmente,",
        text2:"de su capacidad para adaptación y anticipar esos cambios."
    },
    { 
        id: '1', 
        page: "itmanagement",
        img: itmanagement,
        tittle:'IT MANAGEMENT',
        text1:"Las Tecnologías de Información resultan hoy indispensables",
        text2:"para dar soporte y volver más eficiente el desarrollo de una estrategia."
    },
    { 
        id: '1', 
        page: "rrhh",
        img: rrhh,
        tittle:'RECURSOS HUMANOS',
        text1:"Los recursos humanos son el recurso estratégico más importante",
        text2:"en la ejecución de las metas."
    },
    { 
        id: '1', 
        page: "qhse",
        img: qhse,
        tittle:'IMPLEMENTACIÓN DE SISTEMAS DE GESTIÓN QHSE',
        text1:"La dinámica de negocios globalizada exige que las empresas redefinan sus estrategias y sus procesos con la finalidad de lograr el uso",
        text2:"eficiente de los recursos y el aumento de su productividad, para competir con éxito en el mercado mundial."
    },
    { 
        id: '1', 
        page: "atencion-clientes",
        img: atencionClientes,
        tittle:'ATENCIÓN AL CLIENTE',
        text1:"Los Servicios de Atención al cliente son una herramienta esencial para lograr la",
        text2:"fidelización y la permanencia del cliente."
    },
    { 
        id: '1', 
        page: "operaciones",
        img: operaciones,
        tittle:'OPERACIONES',
        text1:"",
        text2:""
    },
    { 
        id: '1', 
        page: "marketing-directo",
        img: marketingDirecto,
        tittle:'MARKETING DIRECTO',
        text1:"Definimos y desarrollamos acciones de Marketing Directo",
        text2:"que generan un fuerte impacto en las operaciones de nuestros clientes"
    },
    { 
        id: '1', 
        page: "capacitacion",
        img: capacitacion,
        tittle:'CAPACITACIÓN',
        text1:"Ofrecemos servicios capacitación y estímulo a las fuerzas de ventas",
        text2:""
    },
    { 
        id: '1', 
        page: "sistemas",
        img: sistemas,
        tittle:'SISTEMAS',
        text1:"Las tecnologías de información resultan hoy indispensables para dar soporte",
        text2:"y volver más eficiente el desarrollo de una estrategia competitiiva."
    },

  ]

export const getInfoByPage = (page) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info.filter(item => item.page === page))
        }, 0)
    })
}