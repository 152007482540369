import { NavBar } from './components/NavBar/NavBar';
import './App.css';
import './index.css'
import { Index } from './components/Index/Index';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import { InsuranceSoluciones } from './components/InsuranceSoluciones/InsuranceSoluciones';
import { Consulting } from './components/Consulting/Consulting';
import { Tpa } from './components/Tpa/Tpa';
import {Footer} from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { B2b2c } from './components/InsuranceSoluciones/B2B2C/B2b2c';
import { Productores } from './components/InsuranceSoluciones/Productores/Productores';
import { Finanzas } from './components/Consulting/Finanzas/Finanzas';
import { AdminGeneral } from './components/Consulting/AdminGeneral/AdminGeneral';
import { Estrategia } from './components/Consulting/Estrategia/Estrategia';
import { Marketing} from './components/Consulting/Marketing/Marketing';
import { ItManagement } from './components/Consulting/ItManagement/ItManagement';
import { RRHH } from './components/Consulting/RRHH/RRHH';
import { QHSE } from './components/Consulting/QHSE/QHSE';
import { AtencionClientes } from './components/Tpa/AtencionClientes/AtencionClientes';
import { Operaciones } from './components/Tpa/Operaciones/Operaciones';
import { MarketingDirecto } from './components/Tpa/MarketingDirecto/MarketingDirecto';
import {Capacitacion} from './components/Tpa/Capacitacion/Capacitacion';
import { Sistemas } from './components/Tpa/Sistemas/Sistemas';
import { NotFound } from './components/NotFound/NotFound';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Index/>} />
        <Route path='/insurance-soluciones' element={<InsuranceSoluciones/>} />
        <Route path='/insurance-soluciones/b2b2c' element={<B2b2c/>} />
        <Route path='/insurance-soluciones/productores' element={<Productores/>} />
        <Route path='/consulting' element={<Consulting/>} />
        <Route path='/consulting/finanzas' element={<Finanzas/>} />
        <Route path='/consulting/admin-general' element={<AdminGeneral/>} />
        <Route path='/consulting/estrategia' element={<Estrategia/>} />
        <Route path='/consulting/marketing' element={<Marketing/>} />
        <Route path='/consulting/it-management' element={<ItManagement/>} />
        <Route path='/consulting/rrhh' element={<RRHH/>} />
        <Route path='/consulting/qhse' element={<QHSE/>} />
        <Route path='/tpa' element={<Tpa/>} />
        <Route path='/tpa/atencion-clientes' element={<AtencionClientes/>} />
        <Route path='/tpa/operaciones' element={<Operaciones/>} />
        <Route path='/tpa/marketing-directo' element={<MarketingDirecto/>} />
        <Route path='/tpa/capacitacion' element={<Capacitacion/>} />
        <Route path='/tpa/sistemas' element={<Sistemas/>} />
        <Route path='*' element={ <NotFound/> } />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
