import React, { useState, useEffect } from 'react'
import { getInfoByPage } from './CarouselData'
import { CarouselItem } from './CarouselItem/CarouselItem'




export const Carousel = (props) => {

    const [info, setInfo] = useState([])

    useEffect(() => {
        getInfoByPage(props.page).then(data => {
            setInfo(data)

        })
    }, [props.page])

    return (
        <section id="carousel" className="header-margin">
            <div id="carouselExampleCaptions" className="carousel slide relative" data-bs-ride="carousel">
                <div className="carousel-inner relative w-full overflow-hidden">
                    {info.map(itemInfo => <CarouselItem {...itemInfo} key={itemInfo.id} />)}
                </div>
                <button
                    className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                    type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                    type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
    )
}
