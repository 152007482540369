const info = [
    {
        id: '1',
        page: 'index',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '2',
        page: 'insurance-soluciones',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '3',
        page: 'b2b2c',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '4',
        page: 'productores',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '5',
        page: 'consulting',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '6',
        page: 'finanzas',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '7',
        page: 'admin-general',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '8',
        page: 'estrategia',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '9',
        page: 'marketing',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '10',
        page: 'itmanagement',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '11',
        page: 'rrhh',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '12',
        page: 'qhse',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '13',
        page: 'tpa',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '14',
        page: 'atencion-clientes',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '15',
        page: 'operaciones',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '16',
        page: 'marketing-directo',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '17',
        page: 'capacitacion',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
    {
        id: '18',
        page: 'sistemas',
        email_destino: 'consultas@agglobalservices.com.ar',
    },
]

export const getMailInfoByPage = (page) => {
    return new Promise((resolve) => {
        resolve(info.filter(item => item.page === page))
    })
}