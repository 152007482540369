import React from 'react'
export const ReinventateCard = () => {

  return (
    <section id="reinventate" className="py-16 lg:py-32 imageBackground bg-no-repeat bg-cover text-white text-center">
    <h1 className="mb-4 text-4xl">¡Reinventate con nosotros!</h1>
    <p className="my-4 text-3xl mb-8">Vamos a optimizar tu negocio con un plan estratégico<br/>
        diseñado para alcanzar tus objetivos.</p>
    <a href="#contacto" className="bg-orange px-4 py-1 rounded-3xl">SUMATE</a> 
</section>
  )
}
