import React, { useState, useEffect } from 'react'
import { InsuranceInfo } from '../InsuranceInfo/InsuranceInfo'
import { getCardInfo } from '../InsuranceCard/InsuranceCardData'
import { InsuranceCard } from '../InsuranceCard/InsuranceCard'

export const InsuranceContainer = () => {

  const [info, setInfo] = useState([])

  useEffect(() => {
    getCardInfo().then(data => {
      setInfo(data)
      
    })
  }, [])

  return (
    <section id="insurance" className="px-4 lg:px-32 py-8 lg:py-16 text-grey">
      <InsuranceInfo/>
      <div className="grid grid-cols-2 mt-[150px] gap-16 mx-0 md:mx-32">
      {info.map(card => <InsuranceCard {...card} key={card.id}/>)}
      </div>
    </section>
  )
}
