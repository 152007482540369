import React from 'react'
import { Link } from 'react-router-dom'

export const CardServices = (props) => {

    return (
        <div className="col-span-3 lg:col-span-1 rounded-3xl shadow-xl py-4 bg-grey-2 text-center text-grey">
            <img className="m-auto w-full px-4" src={props.img} alt='' />
            <h2 className="text-3xl text-orange mt-8 mb-4">{props.tittle}</h2>
            <p className="lg:min-h-[210px] text-sm px-4 mb-8">
               {props.text}
            </p>
            <Link to={props.path} className="rounded-3xl px-6 py-2 bg-grey text-white">VER MÁS</Link>
        </div>
    )
}
