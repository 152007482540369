

const info = [
    { 
        id: '1', 
        tittle:'1. Customización',
        items:"Soluciones end-to-end."
    },
    { 
        id: '2', 
        tittle:'2. Tecnología',
        items:"Integración con aseguradoras para la cotización y emisión de seguros en línea. | Adaptación de soluciones para la mejora de procesos operativos. | App mobile responsive para la autogestión de pólizas (facturación, denunciar de siniestros, realizar nuevas cotizaciones, solicitar asistencia mecánica)."
    },
    { 
        id: '3', 
        tittle:'3. Administración y gestión',
        items:"Centro de soporte disponible para consultas y gestiones. | Asesoramiento continuo para la mejor utilización de la información disponible."
    },
    
]

export const getItemsInfo = (page) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(info)
        }, 0)
    })
}