import React,{useState,useEffect} from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { QHSEContainer } from './QHSEContainer/QHSEContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const QHSE = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("qhse").then(data => {
      setInfo(data)
    })
  }, [])
  return (
    <div className='mt-23'>
        <Carousel page="qhse"/>
        <QHSEContainer/>
        <ContactContainer page={"qhse"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
