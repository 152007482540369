import React,{useState,useEffect} from 'react'
import { Carousel } from '../../Carousel/Carousel'
import { ContactContainer } from '../../Index/ContactContainer/ContactContainer'
import { MarketingContainer } from './MarketingContainer/MarketingContainer'
import { getMailInfoByPage } from '../../Index/ContactContainer/ContactForm/ContactData'

export const Marketing = () => {
  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("marketing").then(data => {
      setInfo(data)
    })
  }, [])
  return (
    
    <div className='mt-23'>
        <Carousel page="marketing"/>
        <MarketingContainer/>
        <ContactContainer page={"marketing"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
