import React,{useState,useEffect} from 'react'
import { getItemInfoByPage } from '../../../GenericItemInfo/GenericItemData'
import { Link } from 'react-router-dom'
import { GenericInfo } from '../../../GenericInfo/GenericInfo'
import { GenericItemInfo } from '../../../GenericItemInfo/GenericItemInfo'

export const AtencionClientesContainer = () => {
    const [info, setInfo] = useState([])

    useEffect(() => {
        getItemInfoByPage("atencion-clientes").then(data => {
            setInfo(data)

        })

    }, [])
    return (
        <section id="main" className="my-8 mx-4 md:mx-32 text-grey">
            <Link to={"/tpa"} className="hover:font-bold text-orange">Volver</Link>
            <GenericInfo text="En AG TPA consideramos que los Servicios de Atención al cliente son una herramienta esencial para lograr la fidelización y la permanencia del cliente. Por este motivo buscamos alinearnos a las estrategias y a los objetivos de calidad que han trazado las empresas que nos eligen, de manera de brindar en su nombre el conjunto de actividades que nos solicitan y convertirnos en un puente de comunicación eficaz entre las necesidades, los objetivos y las expectativas de la empresa contratante y las de su usuario o cliente final."  tittle="Nuestros productos y servicios" />
            <div className="grid grid-cols-2 gap-8 my-8">
                {info.map(item => <GenericItemInfo {...item} key={item.id} />)}
            </div>
        </section>
    )
}
