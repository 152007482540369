import React from 'react'

export const ConsultingInfo = () => {
    return (
        <p className="text-center lg:px-32">El ambiente de negocios actual presenta un flujo constante tanto de desafíos como de oportunidades. Métodos
            como el desarrollo de la estrategia y del liderazgo son el marco necesario para orientar los esfuerzos compartidos.
            Pero las actividades esenciales de la empresa, aquéllas que se realizan día a día, suelen perder su valor estratégico
            por desdibujarse tras la gestión inminente de los cambios.<br /><br />
            Por eso, asesoramos y ejecutamos planes de acción de acuerdo a los objetivos y estrategias establecidas para
            cada cliente.
        </p>
    )
}
