import React,{useEffect,useState} from 'react'
import { Carousel } from '../Carousel/Carousel'
import { ContactContainer } from '../Index/ContactContainer/ContactContainer'
import { InsuranceContainer } from './InsuranceContainer/InsuranceContainer'
import { getMailInfoByPage } from '../Index/ContactContainer/ContactForm/ContactData'


export const InsuranceSoluciones = () => {

  const [info, setInfo] = useState([])

  useEffect(() => {
    getMailInfoByPage("insurance-soluciones").then(data => {
      setInfo(data)
    })
  }, [])
  return (
    <div className='mt-23'>
        <Carousel page={"insurance-soluciones"}/>
        <InsuranceContainer/>   
        <ContactContainer page={"insurance-soluciones"} email_destino={info[0]?.email_destino}/>
    </div>
  )
}
